import React from "react"
// UI
import { Col } from 'rsuite'

function ArtistListLoader(props) {
    return (
    	<Col key="loading" sm={24} xs={24} > Loading... </Col>
    )
}

export default ArtistListLoader