import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
// Main component
import Main from "./Main.js"
// Theming
import * as theming from "./utils/theming"

// Init app 
theming.initTheme()
ReactDOM.render(
    <Main />,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()