// Load list of artists
export const LOAD_ARTISTS = "LOAD_ARTISTS"
export const LOAD_ARTISTS_INDEX_SUCCESS = "LOAD_ARTISTS_INDEX_SUCCESS"
// Load single artist
export const LOAD_ONE_ARTIST_SUCCESS = "LOAD_ONE_ARTIST_SUCCESS"
export const LOAD_SONGS_OF_ONE_ARTIST_SUCCESS = "LOAD_SONGS_OF_ONE_ARTIST_SUCCESS"
// Load playlists
export const LOAD_PLAYLISTS = "LOAD_PLAYLISTS"
export const LOAD_PLAYLISTS_SUCCESS = "LOAD_PLAYLISTS_SUCCESS"
export const LOAD_SINGLE_PLAYLIST_SUCCESS = "LOAD_SINGLE_PLAYLIST_SUCCESS"
// Edit playlist
export const ADD_SONGS_TO_PLAYLIST_RESULT = "ADD_SONGS_TO_PLAYLIST_RESULT"
export const REMOVE_SONGS_FROM_PLAYLIST_RESULT = "REMOVE_SONGS_FROM_PLAYLIST_RESULT"
export const DELETE_PLAYLIST_RESULT = "DELETE_PLAYLIST_RESULT"
export const EDIT_PLAYLIST_RESULT = "EDIT_PLAYLIST_RESULT"
// Songs management
export const ADD_SONGS_TO_QUEUE = "ADD_SONGS_TO_QUEUE"
export const PUT_SONGS_IN_QUEUE = "PUT_SONGS_IN_QUEUE"
export const PLAY_NEXT_SONG = "PLAY_NEXT_SONG"
export const PLAY_PREVIOUS_SONG = "PLAY_PREVIOUS_SONG"
export const STAR_SONG_RESULT = "STAR_SONG_RESULT"
export const CLEAR_QUEUE = "CLEAR_QUEUE"
export const REMOVE_SONGS_FROM_QUEUE = "REMOVE_SONGS_FROM_QUEUE"
export const SEEK_TO_SONG_IN_QUEUE = "SEEK_TO_SONG_IN_QUEUE"
export const TOGGLE_SHUFFLE_ON = "TOGGLE_SHUFFLE_ON"
export const TOGGLE_SHUFFLE_OFF = "TOGGLE_SHUFFLE_OFF"
// Authentication
export const LAZY_LOGIN_IGNORE = "LAZY_LOGIN_IGNORE"
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST"
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGOUT_USER = "LOGOUT_USER"
// API calls
export const BEGIN_ASYNC_OPERATION = "BEGIN_ASYNC_OPERATION"
export const END_ASYNC_OPERATION = "END_ASYNC_OPERATION"
// Search
export const SEARCH_RESULT = "SEARCH_RESULT"
// Albums
export const LOAD_ALBUM_SUCCESS = "LOAD_ONE_ALBUM_SUCCESS"
export const LOAD_ALBUMS_LIST_SUCCESS = "LOAD_ALBUMS_LIST_SUCCESS"
export const STAR_ALBUM_RESULT = "STAR_ALBUM_RESULT"
// Favourites
export const LOAD_FAVOURITES_RESULT = "LOAD_FAVOURITES_RESULT"
// Genres
export const LOAD_SONGS_OF_GENRE_SUCCESS = "LOAD_SONGS_OF_GENRE_SUCCESS"